<template>
  <div>
      <div id="styledElement">
        <b-container id="resizeElement">
          <div class="intro-page">
            <div>
              <b-row>
                <b-col>
                  <div class="quiz-box">
                    <div class="quiz-content">
                      <span class="intro-icon"></span>
                      <div class="title-holder">
                        <h1 class="quiz-title"><span class="subtitle">WHAT’S YOUR</span>Clean Air Personality Type? </h1>
                      </div>
                      <div class="quiz-image">
                        <img src="../assets/img/I1.png">
                      </div>
                      <p class="txt">
                        What do you know about <strong>air pollution</strong>? No, we’re not talking
                        about when that naughty kid at the back of class “lets one
                        slip”, we mean actual air pollution; the <strong>particles</strong> and <strong>gases</strong>
                        that enter our bodies and can <strong>damage our health</strong>. <br /><br />
                        This fun, <strong>fact-packed quiz</strong> takes you through an entire day;
                        from getting ready, travelling, and being at school, to eating
                        dinner and surfing the web in the evening. Each step of the
                        way, you’ll learn how your decisions can make an <strong>impact</strong> on
                        the <strong>air you breathe</strong>.
                        <br /><br />
                        <strong>At the end of the quiz, you’ll be assigned your Clean Air
                        Personality Type. But what will you be? There’s only one
                          way to find out...</strong></p>
                      <div class="quiz-bottom">
                        <div class="bottom-left">
                          <b-button class="btn" @click="play()">START THE QUIZ!</b-button>
                        </div>
                        <div class="bottom-right">
                          <img src="../assets/img/Logos.png">
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                    <div class="blue-footer">
                      <p>These resources were produced by Creature & Co and Global Action Plan as part of the Clean Air Programme, which is supported through the UK Research and Innovation Strategic Priorities Fund (SPF) and delivered by the Met Office and other partners.
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-container>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Intro',
  methods: {
    play () {
      this.$router.push({ name: 'Quiz' })
    }
  }
}
</script>
